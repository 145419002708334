import img1 from '../images/team/img-1.jpg'
import img2 from '../images/team/img-2.jpg'
import img3 from '../images/team/img-3.jpg'
import img4 from '../images/team/img-4.jpg'
import img5 from '../images/team/img-5.jpg'
import img6 from '../images/team/img-6.jpg'

const Team = [
    {
        id: '01',
        title: 'Esther Howard',
        subtitle: 'Creative Director',
        Imga: img1,
        slug: 'Esther-Howard',
    },
    {
        id: '02',
        title: 'Jacob Jones',
        subtitle: 'Head Marketer',
        Imga: img2,
        slug: 'Jacob-Jones',
    },
    {
        id: '03',
        title: 'Wade Warren',
        subtitle: 'Stock Analist',
        Imga: img3,
        slug: 'Wade-Warren',
    },
    {
        id: '04',
        title: 'Esther Howard',
        subtitle: 'Creative Director',
        Imga: img4,
        slug: 'Esther-Howard-2',
    },
    {
        id: '05',
        title: 'Maria Liza',
        subtitle: 'Head Marketer',
        Imga: img5,
        slug: 'Maria-Liza-2',
    },
    {
        id: '06',
        title: 'Wade Warren',
        subtitle: 'Stock Analist',
        Imga: img6,
        slug: 'Wade-Warren-3',
    },

]

export default Team;