// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blog1 from "../images/blog/img-4.jpg";
import blog2 from "../images/blog/img-5.jpg";
import blog3 from "../images/blog/img-6.jpg";

import blogSingleImg1 from "../images/blog/img-4.jpg";
import blogSingleImg2 from "../images/blog/img-5.jpg";
import blogSingleImg3 from "../images/blog/img-6.jpg";






const blogs = [
    {
        id: '1',
        title: 'Why Industry Are A Juicy Target For Cyberattack.',
        title2: 'Express delivery is going to slow down in 2024.',
        tag: 'Surgery',
        slug: 'Why-Industry-Are-A-Juicy-Target-For-Cyberattack',
        screens: blogImg1,
        PageImg: blog1,
        description: 'Dolor sit amet, consectetur adipiscing  Adising fringilla etiam vitae molestie pnar.',
        author: 'Robert William',
        create_at: 'May 27,2022',
        blogSingleImg: blogSingleImg1,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Asbestos Remediation',
    },
    {
        id: '2',
        title: 'Express delivery is going to slow down in 2024',
        title2: 'Will supply chains become supply circles?',
        tag: 'Orthopedic',
        slug: 'Why-Industry-Are-A-Juicy-Target-For',
        screens: blogImg2,
        PageImg: blog2,
        description: 'Dolor sit amet, consectetur adipiscing  Adising fringilla etiam vitae molestie pnar.',
        author: 'Aliza Anny',
        create_at: 'Sep 03, 2024',
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'International Planning',
    },
    {
        id: '3',
        title: 'Will supply chains become supply circles?',
        title2: 'We ensures you the best quality services',
        tag: 'Surgery',
        slug: 'Why-Industry-Are-A-Juicy',
        screens: blogImg3,
        PageImg: blog3,
        description: 'Dolor sit amet, consectetur adipiscing  Adising fringilla etiam vitae molestie pnar.',
        author: 'Takla Beshu',
        create_at: 'Sep 03, 2024',
        blogSingleImg: blogSingleImg3,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Mechanical Engineering',
    },
    {
        id: '4',
        title: 'Will supply chains become supply circles?',
        title2: 'We ensures you the best quality services',
        tag: 'Surgery',
        slug: 'Why-Industry-Are-A-Juicy2',
        screens: blogImg2,
        PageImg: blog1,
        description: 'Dolor sit amet, consectetur adipiscing  Adising fringilla etiam vitae molestie pnar.',
        author: 'Takla Beshu',
        create_at: 'Sep 03, 2024',
        blogSingleImg: blogSingleImg2,
        comment: '35',
        day: '28',
        month: 'MAR',
        blClass: 'format-standard-image',
        animation: '1200',
        catagori: 'Oil & Gas Engineering',
    },







];
export default blogs;



