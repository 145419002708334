
/* Single image */
import img1 from '../images/icon/engineering.svg'
import img2 from '../images/icon/oil-refinery.svg'
import img3 from '../images/icon/turbo.svg'
import img4 from '../images/icon/timing.svg'
import img5 from '../images/icon/recycle-water.svg'
import img6 from '../images/icon/excavator.svg'

import imgD1 from '../images/icon/dark/engineering.svg'
import imgD2 from '../images/icon/dark/oil-refinery.svg'
import imgD3 from '../images/icon/dark/turbo.svg'
import imgD4 from '../images/icon/dark/timing.svg'
import imgD5 from '../images/icon/dark/recycle-water.svg'
import imgD6 from '../images/icon/dark/excavator.svg'



import Simg1 from '../images/service-single/1.jpg'
import Simg2 from '../images/service-single/2.jpg'
import Simg3 from '../images/service-single/3.jpg'
import Simg4 from '../images/service-single/4.jpg'
import Simg5 from '../images/service-single/5.jpg'
import Simg6 from '../images/service-single/6.jpg'

const Services = [
    {
        id: 1,
        image: img1,
        imageDrak: imgD1,
        simag: Simg1,
        subtitle: 'Transport',
        title: 'Mechanical Engineering',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Air-Transport'
    },
    {
        id: 2,
        image: img2,
        imageDrak: imgD2,
        simag: Simg2,
        subtitle: 'Transport',
        title: 'Petroleum Refinery',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Ocean-Transport'
    },
    {
        id: 3,
        image: img3,
        imageDrak: imgD3,
        simag: Simg3,
        subtitle: 'Transport',
        title: 'Power & Energy',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Road-Transport'
    },
    {
        id: 4,
        image: img4,
        imageDrak: imgD4,
        simag: Simg4,
        subtitle: 'Transport',
        title: 'Electrical & Power',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Road-Transport2'
    },
    {
        id: 5,
        image: img5,
        imageDrak: imgD5,
        simag: Simg5,
        subtitle: 'Transport',
        title: 'Sanitary & Plumbing',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Ocean-Transport2'
    },
    {
        id: 6,
        image: img6,
        imageDrak: imgD6,
        simag: Simg6,
        subtitle: 'Transport',
        title: 'Industrial Construction',
        description: 'consectetur adipising elit. Dolor felism lectus gravida uoit. Sempr malesuada arcu facilisis amet sodales ornare.',
        slug: 'Road-Transport3'
    }




];
export default Services;