import pimg1 from '../images/projects/1.jpg';
import pimg2 from '../images/projects/2.jpg';
import pimg3 from '../images/projects/3.jpg';
import pimg4 from '../images/projects/4.jpg';
import pimg5 from '../images/projects/2.jpg';

import psimg1 from '../images/project-single/1.jpg';
import psimg2 from '../images/project-single/2.jpg';
import psimg3 from '../images/project-single/3.jpg';
import psimg4 from '../images/project-single/4.jpg';
import psimg5 from '../images/project-single/5.jpg';






const Projects = [
    {
        id: '01',
        subtitle: 'Recent Project',
        title: 'Chemical Refinery',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions',
        pimg: pimg1,
        psimg: psimg1,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
    {
        id: '02',
        subtitle: 'Recent Project',
        title: 'Mechanical Works',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions2',
        pimg: pimg2,
        psimg: psimg2,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },

    {
        id: '03',
        subtitle: 'Recent Project',
        title: 'Manufacturing Plan',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions3',
        pimg: pimg3,
        psimg: psimg3,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },

    {
        id: '04',
        subtitle: 'Recent Project',
        title: 'Chemical Refinery',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions4',
        pimg: pimg4,
        psimg: psimg4,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },
    {
        id: '05',
        subtitle: 'Recent Project',
        title: 'Manufacturing Plan',
        docomunt: 'Quis id at rhoncus consequat. Aliquam in. Velit phasellus augue tristique integer arcu. Elit sed vestibulum tristique suspendisse ut sit',
        slug: 'Special-Transport-Solutions5',
        pimg: pimg5,
        psimg: psimg5,
        location: '7 Lake Street,London',
        date: '15 Dec 2024',
    },



]
export default Projects;






